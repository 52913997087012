import React, { Component } from 'react';
import { Elements, StripeProvider, CardElement } from 'react-stripe-elements';
import { loadStripe } from '@stripe/stripe-js';
import Header from './components/header.js';
import Footer from './components/footer.js';
import Donate from './components/donate.js';
import DonationOptions from './components/donation-options.js';
import LearnMoreButton from './components/learn-more.js';
import SocialComponent from './components/social-component.js';
import Checkout from './pages/page-donate.js';
import { Link, withRouter } from 'react-router-dom';
import heroImage from './images/hero_image.png';
import './App.scss';
const data = require('./data/data.json');

class App extends Component {
	renderBody = () => {
		return (
			<div>
				{data.body.map((paragraph) => {
					return <p>{paragraph}</p>;
				})}
			</div>
		);
	};

	render() {
		return (
			<div>
				<Header />
				<div className='row'>
					<div className='topTitle'>
						<h1>{data.title}</h1>
					</div>
					<div className='double-column'>
						<div className='column'>
							<div className='section-main'>
								<div className='section-main-title'>
									<img src={heroImage}></img>
									<div style={{ minWidth: '300px' }}></div>
								</div>

								<div className='section-main-description'>
									<p>{this.renderBody()}</p>
									<ul style={{ listStyleType: 'none', padding: '0' }}>
										<li>American Farmland Trust</li>
										<ul>
											<li>
												AFT is stopping the loss of farmland to sprawling
												development, working with communities on smarter growth
												that includes local family farms, and advocating
												environmentally healthy farming practices
											</li>
										</ul>

										<li>The Land Connection</li>
										<ul>
											<li>
												The Land Connection envisions community-based food
												systems where every farmer grows food in a sustainable
												manner and every person can access nutritious, locally
												grown and produced foods.
											</li>
										</ul>

										<li>
											Rural Advancement Foundation International - USA
											(RAFI-USA){' '}
										</li>
										<ul>
											<li>
												RAFI cultivates markets, policies, and communities that
												support thriving, socially just, and environmentally
												sound family farms.
											</li>
										</ul>

										<li>
											El Comite De Apoyo A Los Trabajadores Agricolas (CATA)
										</li>
										<ul>
											<li>
												CATA has been working to empower the migrant community
												since 1979 with migrant farmworkers and low-wage workers
												working alongside supporters and other organizations,
												advocating for social, economic and environmental
												justice.
											</li>
										</ul>

										<li>FamilyFarmed </li>
										<ul>
											<li>
												FamilyFarmed has been a leader to build a better food
												system by eating food produced as locally as possible
												using sustainable, humane and fair practices.
											</li>
										</ul>

										<li>Tilth Alliance </li>
										<ul>
											<li>
												Tilth Alliance is working to build an ecologically
												sound, economically viable and socially equitable food
												system.
											</li>
										</ul>

										<li>Georgia Organics </li>
										<ul>
											<li>
												Georgia Organics connects organic food from Georgia
												farms to Georgia families.
											</li>
										</ul>

										<li>Sustainable Food Center</li>
										<ul>
											<li>
												Sustainable Food Center is cultivating a just and
												regenerative food system so people and the environment
												can thrive.
											</li>
										</ul>

										<li>
											SEEDS (South Eastern Efforts Developing Sustainable
											Spaces){' '}
										</li>
										<ul>
											<li>
												SEEDS is committed to empowering youth and adults
												through garden-based educational programming on healthy
												lifestyles, organic gardening, food security,
												sustainable agriculture, and environmental stewardship.
											</li>
										</ul>

										<li>Agricultural Stewardship Association </li>
										<ul>
											<li>
												ASA protects our community's working farms and forests,
												connects people to the land and promotes a vibrant
												future for agriculture and forestry in our region.
											</li>
										</ul>

										<li>Philadelphia Orchard Project</li>
										<ul>
											<li>
												Philadelphia Orchard Project champions community-led
												efforts to improve access to fresh food through creating
												beautiful and bountiful urban orchards.
											</li>
										</ul>

										<li>Plant Chicago </li>
										<ul>
											<li>
												Plant Chicago cultivates local circular economies by
												running education, small business, and shared space
												indoor agriculture programs out of a former firehouse.
											</li>
										</ul>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className='column'>
						<div className='section-donate'>
							<Donate />
							<DonationOptions stripeAction={this.showModal} />
							<SocialComponent />
							<div class='section-donate-disclaimer'>
								*While we work to ensure accuracy, the stats indicated on this
								page may not be an exact reflection of actual activity.
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

export default withRouter(App);
